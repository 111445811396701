import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import MenuService from '@services/Menu/MenuService';

/* Components List */

// Public Views
// const Login = lazy(() => import('@views/public/Login'));

// Private views (for logged users)
const RegulatorsList = lazy(() => import('@views/private/Regulators/RegulatorsList'));
const RegulatorsUpdate = lazy(() => import('@views/private/Regulators/RegulatorsCreateUpdate/RegulatorsUpdate'));
const RegulatorsCreate = lazy(() => import('@views/private/Regulators/RegulatorsCreateUpdate/RegulatorsCreate'));
// const RegulatorDetails = lazy(() => import('@views/private/Regulators/RegulatorDetails'));
const RegulatorDetails = lazy(() => import('@views/private/Regulators/Details'));

const SourceDetails = lazy(() => import('@views/private/Regulators/SourceDetails'));
const BotHealth = lazy(() => import('@views/private/BotHealth'));
const ClientUsage = lazy(() => import('@views/private/ClientUsage'));
const ClientUsageByFeature = lazy(() => import('@views/private/ClientUsageByFeature'));

const ContentProvidersList = lazy(() => import('@views/private/ContentProviders/ContentProvidersList'));
const ContentProvidersUpdate = lazy(() => import('@views/private/ContentProviders/ContentProvidersUpdate'));

const BulkAddRegulations = lazy(() => import('@views/private/RegLibrary/BulkAddRegulations/BulkAddRegulations'));
const RegulationMetadataList = lazy(() => import('@views/private/RegLibrary/RegulationMetadata/RegulationMetadataList'));
const RegulationMetadataDetail = lazy(() => import('@views/private/RegLibrary/RegulationMetadata/RegulationMetadataDetail'));

const ApiViewerAuthorities = lazy(() => import('@views/private/ApiViewer/Authorities'));
const ApiViewerRegulations = lazy(() => import('@views/private/ApiViewer/Regulations'));
const ApiViewerVersions = lazy(() => import('@views/private/ApiViewer/Versions'));
const ApiViewerContentEditor = lazy(() => import('@views/private/ApiViewer/ContentEditor'));

// Taxonomy Manager
const SelectClient = lazy(() => import('@views/private/TaxonomyManager/SelectClient'));
const ClientMaps = lazy(() => import('@views/private/TaxonomyManager/ClientMaps'));
const MapDetails = lazy(() => import('@views/private/TaxonomyManager/Step-1-MapDetails'));
const DocumentMapping = lazy(() => import('@views/private/TaxonomyManager/Step-2-DocumentMapping'));
const CategoryStructure = lazy(() => import('@views/private/TaxonomyManager/Step-3-CategoryStructure'));
const CategoryMapping = lazy(() => import('@views/private/TaxonomyManager/Step-4-CategoryMapping'));
const ServiceLineStructure = lazy(() => import('@views/private/TaxonomyManager/Step-5-ServiceLineStructure'));
const ServiceLineMapping = lazy(() => import('@views/private/TaxonomyManager/Step-6-ServiceLineMapping'));
const UserMapping = lazy(() => import('@views/private/TaxonomyManager/Step-7-UserMapping'));

// Classification Models
const ClassificationModelDetails = lazy(() => import('@views/private/ClassificationModels/ModelDetails'));
const ClassificationModels = lazy(() => import('@views/private/ClassificationModels/ClassificationModels'));
const ClassificationModelsDashboard = lazy(() => import('@views/private/ClassificationModelsDashboard/ClassificationModelsDashboard'));
const MarketingNoticesList = lazy(() => import('@views/private/MarketingNotices/MarketingNoticesList'));
const MarketingNoticeDetails = lazy(() => import('@views/private/MarketingNotices/MarketingNoticeDetails'));

// Workflow Config
const WorkflowsList = lazy(() => import('@views/private/WorkflowConfig/WorkflowsList'));
const WorkflowDetails = lazy(() => import('@views/private/WorkflowConfig/WorkflowDetails'));

// Client Taxonomies
const ClientTaxonomiesList = lazy(() => import('@views/private/ClientTaxonomies/ClientTaxonomiesList'));
const ClientTaxonomyDetails = lazy(() => import('@views/private/ClientTaxonomies/ClientTaxonomyDetails'));

// Themes Maintenance
const ThemesList = lazy(() => import('@views/private/ThemesMaintenance/ThemesList'));
const ThemeDetails = lazy(() => import('@views/private/ThemesMaintenance/ThemeDetails'));

// Regulation Access Group
const RegulationsList = lazy(() => import('@views/private/RegulationAccessGroup/RegulationsList'));
const RegulationDetails = lazy(() => import('@views/private/RegulationAccessGroup/RegulationDetails'));

// Push Api Dashboard
const PushApiList = lazy(() => import('@views/private/PushApi/PushApiList'));

const PushApiDetails = lazy(() => import('@views/private/PushApi/DetailsContainer'));

// Dashboards Maintenance
const DashboardsList = lazy(() => import('@views/private/DashboardsMaintenance/DashboardsList'));
const DashboardDetails = lazy(() => import('@views/private/DashboardsMaintenance/DashboardDetails'));

// Documents Page
const Documents = lazy(() => import('@views/private/Documents'));

// Workflow Routing
const WorkflowRouting = lazy(() => import('@views/private/WorkflowRouting/WorkflowRoutingList/WorkflowRouting'));
const WorkflowRoutingDetails = lazy(() => import('@views/private/WorkflowRouting/WorkflowRoutingDetails/WorkflowRoutingDetails'))

// Task Automation
const TaskAutomationList = lazy(() => import('@views/private/TaskAutomation/TaskAutomationList/List'));
const TaskAutomationDetails = lazy(() => import('@views/private/TaskAutomation/TaskAutomationDetails/Details'));

// User Groups
const UserGroupsList = lazy(() => import('@views/private/UserGroups/UserGroupsList/UserGroupsList'));
const UserGroupDetails = lazy(() => import('@views/private/UserGroups/UserGroupDetails/UserGroupDetails'));

// Legal Entities
const LegalEntities = lazy(() => import('@views/private/LegalEntities/LegalEntities'));

// Procedures Config
const ProceduresConfig = lazy(() => import('@views/private/ProceduresConfig/ProceduresConfig'));

// Regulators Export
const RegulatorsExport = lazy(() => import('@views/private/RegulatorsExport/RegulatorsExport'));

// Reporting System
const ReportLogs = lazy(() => import('@views/private/ReportingSystem/ReportLogs/ReportLogs'));
const ReportStats = lazy(() => import('@views/private/ReportingSystem/ReportStats/ReportStats'));

export const suspenseFallback = () => {
    return (
        <div className="wrapper">
            <div className="card">
                <div className="card-body">Loading...</div>
            </div>
        </div>
    )
}

export const Routes = () => (
    <Suspense fallback={suspenseFallback()}>
        <Switch>
            <Route path="/regulators/create" main="regulators" component={RegulatorsCreate} />
            <Route path="/regulators/update/:id" main="regulators" component={RegulatorsUpdate} />
            <Route path="/regulators/details/:id" main="regulators" component={RegulatorDetails} />
            <Route path="/regulators" main="regulators" component={RegulatorsList} />
            <Route path="/source/details/:name/:id" main="regulators" component={SourceDetails} />


            <Route path="/bot-health" main="bot-health" component={BotHealth} />
            <Route path="/client-usage" main="client-usage" component={ClientUsage} />
            <Route path="/usage-report" main="usage-report" component={ClientUsageByFeature} />
            <Route path="/content-providers/:id" main="content-providers" component={ContentProvidersUpdate} />
            <Route path="/content-providers" main="content-providers" component={ContentProvidersList} />

            {/* Reg Library */}
            <Route main="/reg-library/bulk-add-regulations" path="/reg-library/bulk-add-regulations" component={BulkAddRegulations} />
            <Route main="/reg-library/metadata" path="/reg-library/metadata/:id" component={RegulationMetadataDetail} />
            <Route main="/reg-library/metadata" path="/reg-library/metadata" component={RegulationMetadataList} />

            {/* Api Viewer */}
            <Route main="api-viewer/authorities" path="/api-viewer/authorities" component={ApiViewerAuthorities} />
            <Route main="api-viewer/authorities" path="/api-viewer/regulations/:id/:title" component={ApiViewerRegulations} />
            <Route main="api-viewer/authorities" path="/api-viewer/versions/:id/:title" component={ApiViewerVersions} />
            <Route main="api-viewer/authorities" path="/api-viewer/content/:id" component={ApiViewerContentEditor} />

            {/* Taxonomy Manager */}
            <Route main="taxonomy-manager" path="/taxonomy-manager/step-7/:clientId/:mapId/:clientName" component={UserMapping} />
            <Route main="taxonomy-manager" path="/taxonomy-manager/step-6/:clientId/:mapId/:clientName" component={ServiceLineMapping} />
            <Route main="taxonomy-manager" path="/taxonomy-manager/step-5/:clientId/:mapId/:clientName" component={ServiceLineStructure} />
            <Route main="taxonomy-manager" path="/taxonomy-manager/step-4/:clientId/:mapId/:clientName" component={CategoryMapping} />
            <Route main="taxonomy-manager" path="/taxonomy-manager/step-3/:clientId/:mapId/:clientName" component={CategoryStructure} />
            <Route main="taxonomy-manager" path="/taxonomy-manager/step-2/:clientId/:mapId/:clientName" component={DocumentMapping} />
            <Route main="taxonomy-manager" path="/taxonomy-manager/step-1/:clientId/:mapId/:clientName" component={MapDetails} />
            <Route main="taxonomy-manager" path="/taxonomy-manager/step-1/:clientId/create/:clientName" component={MapDetails} />
            <Route main="taxonomy-manager" path="/taxonomy-manager/client-maps/:id/:name" component={ClientMaps} />
            <Route main="taxonomy-manager" path="/taxonomy-manager" component={SelectClient} />

            {/* Classification Models */}
            <Route main="classification-models" path="/classification-models/:id" component={ClassificationModelDetails} />
            <Route main="classification-models" path="/classification-models" component={ClassificationModels} />
            <Route main="classification-models" path="/models-dashboard" component={ClassificationModelsDashboard} />

            {/* Marketing Notices */}
            <Route main="corlytics-updates" path="/corlytics-updates/:id" component={MarketingNoticeDetails} />
            <Route main="corlytics-updates" path="/corlytics-updates" component={MarketingNoticesList} />

            {/* Workflow Config */}
            <Route path="/workflow-config/:id/:clientId" main="workflow-config" component={WorkflowDetails} />
            <Route path="/workflow-config" main="workflow-config" component={WorkflowsList} />

            {/* Client Taxonomies */}
            <Route main="client-taxonomies" path="/client-taxonomies/:id/:name" component={ClientTaxonomyDetails} />
            <Route main="client-taxonomies" path="/client-taxonomies" component={ClientTaxonomiesList} />

            {/* Themes Maintenance */}
            <Route main="themes" path="/themes/:id/:name" component={ThemeDetails} />
            <Route main="themes" path="/themes" component={ThemesList} />

            {/* Regulation Access Group */}
            <Route main="regulation-access-group" path="/regulation-access-group/:id" component={RegulationDetails} />
            <Route main="regulation-access-group" path="/regulation-access-group" component={RegulationsList} />

            {/* Push Api Dashboard */}
            <Route main="push-api" path="/push-api/:id" component={PushApiDetails} />
            <Route main="push-api" path="/push-api" component={PushApiList} />

            {/* Dashboard Maintenance */}
            <Route main="dashboards" path="/dashboards/:id" component={DashboardDetails} />
            <Route main="dashboards" path="/dashboards" component={DashboardsList} />

            {/* Documents Page */}
            <Route path="/documents" component={Documents} main="documents" />

            {/* Workflow Routing */}
            <Route path="/workflow-routing/:id/:clientId" component={WorkflowRoutingDetails} main="workflow-routing" />
            <Route path="/workflow-routing" component={WorkflowRouting} main="workflow-routing" />

            {/* Task Automation */}
            <Route path="/auto-task/:id" component={TaskAutomationDetails} main="auto-task" />
            <Route path="/auto-task" component={TaskAutomationList} main="auto-task" />

            {/* User Groups */}
            <Route path="/user-groups/:id/:clientId" component={UserGroupDetails} main="user-groups" />
            <Route path="/user-groups" component={UserGroupsList} main="user-groups" />

            {/* Legal Entities */}
            <Route path="/legal-entities" component={LegalEntities} main="legal-entities" />

            {/* Procedures Config */}
            <Route path="/procedures-config" component={ProceduresConfig} main="procedures-config" />

            {/* Regulators Export */}
            <Route path="/regulators-export" component={RegulatorsExport} main="regulators-export" />

            {/* Reporting System */}
            <Route path="/report-logs" component={ReportLogs} main="report-logs" />
            <Route path="/report-statistics" component={ReportStats} main="report-statistics" />

            {/* <Route path="/login" name="Login" component={Login} /> */}

            <Route path="*">
                <Redirect to={MenuService.defineHomepage()} />
                {/* <Redirect to="/regulators" /> */}
            </Route>
        </Switch>
    </Suspense>
)